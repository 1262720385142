const mapper = {
    'application/pdf': 'pdffile',
    'text/plain': 'txtfile',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docxfile',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsxfile',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptxfile',
    '': 'file'
}

export function fileTypeIcon(contentType) {
    const icon = mapper[contentType] || mapper[Object.keys(mapper).find(k => contentType.startsWith(k)) || ''];
    return `dx-icon dx-icon-${icon}`;
}