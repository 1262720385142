import {useAuth} from "../contexts/auth";
import {useComm} from '../contexts/communication';

export function apiUrl(url) {
    return url.startsWith('https://') || url.startsWith('http://')
        ? url
        : `${process.env["REACT_APP_API_URL"].replace(/\/+$/,'')}/${url.replace(/^\/+/,'')}`;
}

export function useApiClient()
{
    const [{user}, comm] = [useAuth(), useComm()];

    async function safeRead(response, reader) {
        if (reader === 'text') return response.text();
        if (reader === 'blob') return response.blob();
        const backup = response.clone();
        try {
            return await response.json();
        } catch {
            return await backup.text();
        }
    }

    function call(requestOptions)
    {
        const {url, payload, reader, ...restOptions} = requestOptions;
        const resource = apiUrl(url);
        const fetchOptions = {
            ...restOptions,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.token}`,
                'ngrok-skip-browser-warning': true,
                ...(payload && { 'Content-Type': 'application/json'}),
                ...restOptions.headers
            },
            ...(payload && { body: JSON.stringify(payload)})
        };
        return new Promise((resolve, reject) =>
            fetch(resource, fetchOptions)
                // got Response, throw if not successful
                .then(async (response) => {
                    const content = await safeRead(response, reader);
                    if (response.ok) resolve(content);
                    else throw content || response.statusText || response.status;
                })
                .catch((err) => comm.trigger('errorToast', {message: err}))
                .then(reject));
    }

    return {
        get: (url, options) =>
            call({ url, method: 'GET', ...options }),
        post: (url, payload, options) =>
            call({ url, payload, method: 'POST', ...options }),
        put: (url, payload, options) =>
            call({ url, payload, method: 'PUT', ...options }),
        patch: (url, payload, options) =>
            call({ url, payload, method: 'PATCH', ...options }),
        delete: (url, payload, options) =>
            call({ url, payload, method: 'DELETE', ...options }),
        postForm: (url, formData, options) =>
            call({ url, method: 'POST', body: formData, ...options }),
        getObjectUrl: (url, options) =>
            call({ url, method: 'GET', reader: 'blob', ...options }).then(URL.createObjectURL)
    }
}

