import {apiUrl} from "./api-client"

function userData(user, token) {
  const avatar = (user.id % 50).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  return {
    ...user,
    token,
    avatarUrl: `https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/${avatar}.png`
  }
}

export async function signIn(email, password) {

  try {
    // Send request
    // console.log(email, password);
    const response = await window.fetch(apiUrl('user/login'), {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'ngrok-skip-browser-warning': true },
      body: JSON.stringify({ login: email, password })
    });
    const { user, token } = await response.json();
    window.localStorage.setItem('token', token);

    return {
      isOk: true,
      data: userData(user, token)
    };
  }
  catch {
    return {isOk: false, message: "Authentication failed"};
  }
}

export async function getUser() {
  try {

    const token = window.localStorage.getItem('token');
    const response = await window.fetch(apiUrl('user/me'), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization' : `Bearer ${token}`,
        'ngrok-skip-browser-warning': true }
    });
    const user = await response.json();

    return {
      isOk: true,
      data: userData(user, token)
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    // console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    // console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
