import React, {useState, useEffect, createContext, useContext, useCallback} from 'react';
import EventsComm from '../utils/utils.events-comm';

const EchoEvents = new EventsComm();

function CommProvider(props) {
  return (
    <CommContext.Provider value={EchoEvents} {...props} />
  );
}

const CommContext = createContext({EchoEvents});
const useComm = () => useContext(CommContext);

export {CommProvider, useComm}
