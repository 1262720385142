import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DropDownButton from 'devextreme-react/drop-down-button';
import TextArea from 'devextreme-react/text-area';
import Gallery from 'devextreme-react/gallery';
import {Button} from 'devextreme-react/button';
import {LoadIndicator} from 'devextreme-react/load-indicator';
import {Popup} from 'devextreme-react/popup';
import './home.scss';
import {useComm} from '../../contexts/communication';
import {useAuth} from "../../contexts/auth";
import {useNavigation} from "../../contexts/navigation";
import FileUploader from 'devextreme-react/file-uploader';
import {useIsMount} from '../../utils/isMountHook';
import * as signalR from '@microsoft/signalr';
import {useApi} from "../../api/api-methods";
import {formatBytes} from "../../utils/utils.num";
import {apiUrl} from "../../api/api-client";
import {fileTypeIcon} from "../../utils/file-type-icons"
import {TextBox, Button as TextBoxButton} from "devextreme-react/text-box";

const buttonDropDownOptions = {width: 200};

export default function Home() {
  // -------------------------------------------------------------------------------------------------------------------
  // hooks
  const isMount = useIsMount();
  const comm = useComm();
  const navigate = useNavigate();
  const {navigationData: {currentPath}} = useNavigation();
  const {conversationId} = useParams();
  const {user} = useAuth();
  const fileUploader = useRef(null);
  const clickedImage = useRef(null);
  const api = useApi();
  // -------------------------------------------------------------------------------------------------------------------
  // state
  const [connection, setConnection] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [models, setModels] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [files, setFiles] = useState([]);
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(false);
  const [buttonText, setButtonText] = useState('Send');
  const [popupVisible, setPopupVisible] = useState(false);
  const [settings, setSettings] = useState({ allowedContentTypes: ['image/*'], maxFileSize: 100480 });
  const [nameEditing, setNameEditing] = useState(false);
  // -------------------------------------------------------------------------------------------------------------------
  // methods
  function onInit()
  {
    if (connection) return;

    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(apiUrl('streamchat'), { accessTokenFactory: () => user.token})
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);

    if (!models.length) api.gptModel.getActive()
        .then(ms => {
          if (!Array.isArray(ms) || !ms.length) return;
          setModels(ms);
          if(!selectedModel) setSelectedModel(ms[0]);
        });

    api.file.uploadSettings().then(setSettings);

    // if (conversationId) {
      // todo: ajax call to get conversation by id
      // setConversation(response)
    //}
  }

  async function fetchConversation() {
    console.log('fetchConversation', conversationId);
    await api.conversation.getFullById(conversationId)
        .then(chat => {
          setConversation(chat);
          setMessages(chat.messages.map(m => ({
            text: m.message,
            isBot: m.isBot,
            atts: m.attachments.map(a => ({
              src: apiUrl(`file/download/${a.id}/${a.fileName}`),
              name: a.originalName,
              type: a.contentType,
              size: a.size }))
          })));
        })
        .catch(() => {
            setConversation(null);
            navigate('home');
        });
  }

  async function getOrCreateConversation() {
    if (conversation) return conversation;

    const chat = await api.conversation.startNew(selectedModel.id, prompt, files.map(f => f.uploadId).filter(id => !!id));
    setConversation(chat);
    return chat;
  }

  async function performSendPrompt() {
    setLoadIndicatorVisible(true);
    setButtonText('Sending');
    const currentConversation = await getOrCreateConversation();

    setMessages([...messages,
      {text: prompt, isBot: false, atts: files.map(f => ({name: f.fileName, src: URL.createObjectURL(f), size: f.size, type: f.type }))},
      {text: '', isBot: true}]);
    comm.trigger('gpt-prompt-sent', currentConversation);
  }

  async function sendPrompt() {
    if (!prompt.trim().length) {
      comm.trigger('errorToast', {message: 'Please type something to send.'});
      return;
    }
    await performSendPrompt();
  }

  function fileUpdate({file, request}) {
    if (!request.status) Object.assign(file, { status: 'Uploading...'});
    else if (request.status >= 299) Object.assign(file, { success: false, status: `Upload failed: ${request.response}`});
    else Object.assign(file, { success: true, uploadId: request.response, status: 'Uploaded'});
    setFiles([...files]);
  }

  function fileRemove(file, ix) {
    new Promise((resolve, reject) => file.uploadId ? resolve(file) : reject(file))
        .then(f => api.file.remove(f.uploadId, conversationId))
        .catch((f) => 'not uploaded OR delete failed')
        .finally(() => {
          setFiles([...files.slice(0, ix), ...files.slice(ix + 1)]);
        });
  }

  async function reactWithChatCompletion(prompt) {

    if (conversation && +conversationId !== conversation.id) navigate(`/home/${conversation.id}`);

    await connection.start();

    const [lastMessage] = messages.slice(-1);
    const messageFiles = files.filter(i => i.uploadId).map(i => ({
      fileName: i.uploadId,
      contentType: i.type,
      originalName: i.fileName
    }));
    setPrompt('');
    setFiles([]);
    let isFirst = true;

    console.log('Connected!', user);
    connection.stream('Streamlined', conversation.id, prompt, messageFiles).subscribe({
      next: (item) => {
        console.log('StreamData', item);
        lastMessage.text += item;
        lastMessage.streaming = true;
        setMessages([...messages]);
        if (isFirst) {
          document.querySelector('.dx-card').scrollTo(0, document.querySelector('.dx-card').scrollHeight);
          isFirst = false;
        }
      }, complete: () => {
        console.log('Stream completed');
        connection.stop();

        if (files.length) {
          setFiles([]);
          fileUploader.current.instance.reset();
        }
        setLoadIndicatorVisible(false);
        setButtonText('Send');
      }, error: (err) => {
        console.log('Streaming error: ', err);
        comm.trigger('errorToast', {message: `Streaming error: ${err}`});
      }
    });
    // }).catch(e => console.log('Connection failed: ', e));

    // basic chat completion - no stream
    // const response = await apiChatPrompt('botx-model-deployment', prompt, messages, 0.5, 300);
    // setMessages([...messages, {text: response, isBot: true}]);
  }

  async function onEnterKey(e) {
    console.log('onKeyPress', e);
    // ctrl+enter to send
    if (e.event.ctrlKey) {
      e.event.preventDefault();
      await sendPrompt();
    }
  }

  async function onFilesValueChanged(e) {
    setFiles(e.value);
  }

  function onConversationImageClicked(e) {
    console.log('onConversationImageClicked', e);
    clickedImage.current = e.target;
    setPopupVisible(true);
  }

  function onPopupImageShown(e) {
    if (!clickedImage.current) return;
    document.querySelector('#imagePreviewPopup').src = clickedImage.current.src;
  }

  function renderAttachment(file, ix) {
    const {name, size, type, src, uploadId, status} = file;
    const url = file instanceof File ? URL.createObjectURL(file) : src;
    const canDelete = !!uploadId;
    const onClick = fileRemove.bind(this, file, ix);
    const image = type.startsWith('image/') && !!url;
    return (<div key={url || uploadId || name} className={"attachment"} style={{textAlign: 'left', display: 'flex', position: 'relative', border: '1px solid #eee'}}>
      <div className="attachment-icon" style={{width: 90, height: 90}}>
        {image &&
            <img src={url} onClick={onConversationImageClicked} alt={name}
                 style={{maxHeight: 70, maxWidth: 70, margin: 10 }}/>}
        {!image &&
            <a href={src} download={name}>
              <i className={fileTypeIcon(type)} style={{fontSize: 70}}/>
            </a>}
      </div>
      <div className="attachment-desc">
        <div className="attachment-name" style={{fontWeight: 'bold', paddingTop: '1em'}}>{name}</div>
        <div className="attachment-size" style={{fontSize: 12}}>{formatBytes(size)}</div>
        <div className="attachment-status" style={{fontSize: 12}}>{status}</div>
        {canDelete && <Button icon="close" onClick={onClick} />}
      </div>
    </div>);
  }

  function toggleClass(e) {
    e.target.className = (e.target.className === 'adjust-size') ? '' : 'adjust-size';
  }

  // -------------------------------------------------------------------------------------------------------------------
  // effects
  /*  useEffect(() => {
      if (isMount) return;
      console.log('xsrfToken useEffect', xsrfToken);
      fileUploader.current.instance.upload();
      // todo on submit call perform and clean files etc
    }, [xsrfToken]); // will be triggered when xsrfToken is set above*/

  useEffect(() => {
    if (isMount) return;
    const [prevMessage, lastMessage] = messages.slice(-2);
    if (!lastMessage || lastMessage.streaming || (lastMessage.isBot && lastMessage.text) || !prevMessage) return;
    void reactWithChatCompletion(prevMessage.text);
  }, [messages, isMount]);

  useEffect(() => {
    if (isMount) return;
    console.log('currentPath, conversationId useEffect', currentPath, conversationId);
    if (conversationId) {
      fetchConversation();
    } else {
      setConversation(null);
      setMessages([]);
    }
  }, [currentPath, conversationId, user]);

  useEffect(() => {
    onInit();
  });

  // -------------------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className={'content-block home-height-stretch'}>
        {/* <div className={'dx-card responsive-paddings'}> */}
        <div className={'dx-card'}>
          <div className={'dx-card-header'}>
            <div className={'dx-card-header-content'}>
              {/*<h5>{conversation?.summary || 'Conversation'}</h5>*/}
              <TextBox
                  className={"header" + (nameEditing ? " edit" : "") }
                       readOnly={!nameEditing}
                       focusStateEnabled={nameEditing}
                       hoverStateEnabled={nameEditing}
                       value={conversation?.summary || 'Conversation'}
                       onEnterKey={() => setNameEditing(false)}
                       maxLength={32}
                       width={'100%'}>
                  <TextBoxButton
                      name="edit"
                      location="after"
                      options={{ disabled: !conversation, icon: 'edit', onClick:() => setNameEditing(!nameEditing) }} />
              </TextBox>
              <DropDownButton
                text={selectedModel?.title || 'Select model'}
                icon="formula"
                dropDownOptions={buttonDropDownOptions}
                dataSource={models}
                keyExpr={'id'}
                displayExpr={'title'}
                disabled={!!conversationId}
                onItemClick={(e) => setSelectedModel(e.itemData)}
              />
            </div>
          </div>
          <div className={'dx-card-block'}>
            <div className={'conversation'}>
              {messages.map((item, index) => (
              <div key={index} >
                {item.atts && item.atts.length > 0 && (
                <div className="attachments">
                  <Gallery
                    items={item.atts}
                    height={110}
                    width={'100%'}
                    initialItemWidth={320}
                    focusStateEnabled={false}
                    loop={false}
                    showIndicator={false}
                    showNavButtons={true}
                    itemRender={renderAttachment}
                    />
                </div>)}
                <div className={`conversation-item ${item.isBot ? 'bot' : 'user'}`}>
                  <div className={'image-container'}>
                    <div className={'user-image'}/>
                  </div>
                  <div className={'conversation-item-text'} dangerouslySetInnerHTML={{__html: item.text}}></div>
                </div>
              </div>
              ))}
              <div id="anchor"></div>
            </div>
          </div>
        </div>
        <div className={'user-input'}>
          {files.length > 0 && (
              <div className="attachments">
                <Gallery
                    items={files}
                    height={110}
                    width={'100%'}
                    initialItemWidth={320}
                    loop={false}
                    focusStateEnabled={false}
                    showIndicator={false}
                    showNavButtons={true}
                    itemRender={renderAttachment}
                />
              </div>)}
          <div className={'input-wrapper'}>
            <Button
                id="attach-file-button"
              icon="attach"
              type="normal"
            />
            <TextArea value={prompt} placeholder={'Type here... (Ctrl+Enter to send)'} autoResizeEnabled={true}
                      valueChangeEvent="input"
                      onEnterKey={onEnterKey} disabled={loadIndicatorVisible}
                      onValueChanged={e => setPrompt(e.value)}>
            </TextArea>
            <Button width={100}
                    id="sendButton"
                    type="default"
                    stylingMode="contained"
                    disabled={loadIndicatorVisible || !selectedModel}
                    onClick={sendPrompt}>
              <LoadIndicator className="button-indicator" visible={loadIndicatorVisible}/>
              <span className="dx-button-text">{buttonText}</span>
            </Button>
          </div>
          <div>
            <FileUploader
                ref={fileUploader} disabled={loadIndicatorVisible}
                visible={false}
                dialogTrigger={'#attach-file-button'}
                accept={settings.allowedContentTypes.join(',')}
                maxFileSize={settings.maxFileSize}
                uploadMode="instantly"
                uploadUrl={apiUrl('file/upload')}
                multiple={true}
                onValueChanged={onFilesValueChanged}
                onUploadStarted={fileUpdate}
                onUploadError={fileUpdate}
                onUploaded={fileUpdate}
                uploadHeaders={{ Authorization: `Bearer ${user.token}`}}
                showFileList={false}
                uploadCustomData={{ conversationId }}
                value={files}
            />
          </div>
          <div className={'footer'}>
            Copyright © 2019-{new Date().getFullYear()} BotX s.r.o. Build with ❤️ <a
              href="https://www.botx.cloud/">botx.cloud</a>
          </div>
        </div>
      </div>
      <Popup
          visible={popupVisible}
          onHiding={() => setPopupVisible(false)}
          hideOnOutsideClick={true}
          showCloseButton={true}
          onShown={onPopupImageShown}
          resizeEnabled={true}
          dragEnabled={true}
          title={clickedImage.current?.alt}>
        <div className="popup-content">
          <img id="imagePreviewPopup" className="adjust-size" alt="preview" onClick={toggleClass} />
        </div>
      </Popup>
    </React.Fragment>
  )
}
