import {useApiClient} from "./api-client";

function userData({user, token}) {
    const avatar = (user.id % 50).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    return {
        ...user,
        token,
        avatarUrl: `https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/${avatar}.png`
    }
}

export function useApi() {
    const client = useApiClient();

    return {
        conversation: {
            getById: (id) => client.get(`conversation/${id}`),
            getFullById: (id) => client.get(`conversation/${id}/full`),
            startNew: (modelId, prompt, files) => client.post('conversation', {modelId, prompt, files}),
            getLastN: (topN) => client.get(`conversation/last/${isNaN(+topN) ? '' : +topN}`),
            rename: (id, newName) => client.patch(`conversation/${id}/rename`, { summary: newName }),
            delete: (id) => client.delete(`conversation/${id}`)
        },
        gptModel: {
            getActive: () => client.get('gptmodel/active')
        },
        user: {
            login: (login, password) => client.post('user/login', { login, password }),
            getMe: () => client.get('user/me').then(userData)
        },
        file: {
            remove: (fileName, conversationId) =>
                client.delete('file/remove', {fileName, conversationId: +conversationId}),
            uploadSettings: () => client.get('file/upload/settings')
        }

    }
}
