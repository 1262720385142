export function formatBytes(bytes, decimals = 2) {
    const k = 1024, units = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];
    const dm = Math.max(0, decimals);
    const i = Math.min(units.length - 1, Math.max(0, Math.floor(Math.log(bytes) / Math.log(k))));

    const opts = {style: "unit", unit: units[i], trailingZeroDisplay: "stripIfInteger", maximumFractionDigits: dm};
    return new Intl.NumberFormat(undefined, opts).format(bytes / Math.pow(k, i));


}
