import React, {useEffect, useRef, useState} from 'react';
import {useComm} from '../src/contexts/communication';
import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { Toast } from 'devextreme-react/toast';

export default function Content() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const comm = useComm();

  function onHiding() {
    setShowToast(false);
  }

  useEffect(() => {
    comm.subscribe('errorToast', ({message}) => {
      setToastMessage(message);
      setShowToast(true);
    });
  }, []);

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='*'
          element={<Navigate to='/home' />}
        />
      </Routes>
      <Toast
        visible={showToast}
        message={toastMessage}
        type="error"
        onHiding={onHiding}
        displayTime={5000}
      />
      {/* <Footer>
        Copyright © 2019-{new Date().getFullYear()} BotX s.r.o. Build with ❤️ https://www.botx.cloud/
      </Footer> */}
    </SideNavBarLayout>
  );
}

