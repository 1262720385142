export const navigation = [
  {
    id: 'home',
    text: 'Home',
    path: '/home',
    icon: 'home',
    order: 0
  },
  {
    id: 'my-conversations',
    text: 'My Conversations',
    icon: 'home',
    items: [],
    order: 1,
    disabled: true
  },
  {
    id: 'my-gpts',
    text: 'My GPTs',
    icon: 'folder',
    items: [],
    order: 2,
    disabled: true
  },
  {
    id: 'settings',
    text: 'Settings',
    icon: 'folder',
    items: [
      {
        id: 'my-profile',
        text: 'My Profile',
        path: '/profile',
        icon: 'user',
        order: 0
      },
      {
        id: 'administration',
        text: 'Administration',
        path: '/tasks',
        icon: 'card',
        order: 1
      }
    ],
    order: 3
  }
];
