/**
 * S.I.
 * This is a stupid simple (yet beautiful and powerful) message system for inter-component communication.
 */
class EventsComm {

  get OrphanRule() {
    return Object.freeze({
      IGNORE: -1,
      ACCUMULATE: 1,
      SINGLE_LAST_CALL: 2
    });
  }

  constructor() {
    this.events = new Map();
    this.orphanedCalls = [];
  }

  #accumulateMapPropArray(mapRef, key, arrayPropName, callback) {
    if (mapRef.has(key)) {
      mapRef.get(key)[arrayPropName].push(callback)
    } else {
      mapRef.set(key, {[arrayPropName]: [callback]});
    }
  }

  #invokeOrphans(eventId, callback) {
    let i = this.orphanedCalls.length - 1;
    while (i >= 0) {
      if (this.orphanedCalls[i].eventId === eventId) {
        callback.call(this, this.orphanedCalls[i].arg);
        this.orphanedCalls.splice(i, 1);
      }
      i--;
    }
  }

  subscribe(eventId, callback, singleton = true) {
    this.#invokeOrphans(eventId, callback);
    if (singleton) {
      this.events.set(eventId, {callbacks: [callback]});
    } else {
      this.#accumulateMapPropArray(this.events, eventId, 'callbacks', callback);
    }
  }

  trigger(eventId, arg, orphansRule = this.OrphanRule.IGNORE) {
    if (!this.events.has(eventId)) {
      switch (orphansRule) {
        case this.OrphanRule.ACCUMULATE:
          this.orphanedCalls.push({eventId, arg});
          break;
        case this.OrphanRule.SINGLE_LAST_CALL:
          const existing = this.orphanedCalls.find(oc => oc.eventId === eventId);
          if (existing) {
            existing.arg = arg;
          } else {
            this.orphanedCalls.push({eventId, arg});
          }
          break;
        case this.OrphanRule.IGNORE:
          console.warn(`EventsComm warning: ${eventId} not registered => ignoring`);
          break;
      }
      return;
    }
    const event = this.events.get(eventId);
    event.callbacks.forEach(callback => callback.call(this, arg));
  }
}

export default EventsComm;