import React, {useEffect, useRef, useCallback, useState} from 'react';
import {useLocation } from 'react-router-dom';
import TreeView from 'devextreme-react/tree-view';
import {navigation} from '../../app-navigation';
import {useScreenSize} from '../../utils/media-query';
import './SideNavigationMenu.scss';
import * as events from 'devextreme/events';
import {useApi} from "../../api/api-methods";
import {useComm} from "../../contexts/communication";

export default function SideNavigationMenu(props) {
  const location = useLocation();
  const comm = useComm();
  const api = useApi();

  const {children, selectedItemChanged, openMenu, compactMode, onMenuReady} = props;
  const {isLarge} = useScreenSize();
  const [menuItems, setMenuItems] = useState([]);
  const [conversationNode, setConversationNode] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [current, setCurrent] = useState(null);

  function normalizePath() {
    return navigation.map((item) => (
      {...item, expanded: isLarge, path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path}
    ))
  }

  const treeViewRef = useRef(null);
  const wrapperRef = useRef();

  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', (e) => {
      openMenu(e);
    });
  }, [openMenu]);

  function conversationMenuItem(dbConversation, ix) {
    return {
      path: `/home/${dbConversation.id}`,
      id: dbConversation.uuid,
      dbid: dbConversation.id,
      text: dbConversation.summary,
      icon: 'message'
    }
  }

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (location.pathname !== undefined) {
      treeView.selectItem(location.pathname);
      treeView.expandItem(location.pathname);
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [location, compactMode]);

  // on change of menuItems load conversations if necessary
  useEffect(() => {
    if (!menuItems || !menuItems.length) return;
    const menuNode = conversationNode || menuItems?.find(i => i.id === 'my-conversations');
    if (!conversationNode && menuNode) setConversationNode(menuNode);
    if (!menuNode || menuNode.items?.length) return;

    // load last user's conversations
    api.conversation.getLastN().then(setConversations);
  }, [menuItems]);

  useEffect(() => {
    if (!conversationNode) return;

    conversationNode.items = conversations.map(conversationMenuItem);
    conversationNode.disabled = conversations.length === 0;

    setMenuItems([...menuItems]);
  }, [conversations]);

  useEffect(() => {
    if(!current) return;
    const existing = conversations?.findIndex(c => c.id === current.id) ?? -1;
    if (existing == 0) return;
    if (existing > 0) setConversations([current, ...conversations.slice(0, existing), ...conversations.slice(existing + 1)]);
    else setConversations([current, ...conversations]);
  }, [current]);

  useEffect(() => {
    setMenuItems(normalizePath());
    comm.subscribe('gpt-prompt-sent', (conversation) => setCurrent(conversation));
    }, []);

  return (
    <div className={'side-navigation-menu'} ref={getWrapperRef}>
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          //items={items}
          dataSource={menuItems}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          selectByClick={true}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
    </div>
  );
}
